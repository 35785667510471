@font-face {
    font-family: "Titillium Web";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-300.eot");
    src: local(""),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-300.eot?#iefix") format("embedded-opentype"),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-300.woff2") format("woff2"),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-300.woff") format("woff"),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-300.ttf") format("truetype"),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-300.svg#TitilliumWeb") format("svg");
}

@font-face {
    font-family: "Titillium Web";
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-300italic.eot");
    src: local(""),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-300italic.eot?#iefix") format("embedded-opentype"),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-300italic.woff2") format("woff2"),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-300italic.woff") format("woff"),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-300italic.ttf") format("truetype"),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-300italic.svg#TitilliumWeb") format("svg");
}

@font-face {
    font-family: "Titillium Web";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-regular.eot");
    src: local(""),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-regular.eot?#iefix") format("embedded-opentype"),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-regular.woff2") format("woff2"),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-regular.woff") format("woff"),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-regular.ttf") format("truetype"),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-regular.svg#TitilliumWeb") format("svg");
}

@font-face {
    font-family: "Titillium Web";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-italic.eot");
    src: local(""),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-italic.eot?#iefix") format("embedded-opentype"),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-italic.woff2") format("woff2"),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-italic.woff") format("woff"),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-italic.ttf") format("truetype"),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-italic.svg#TitilliumWeb") format("svg");
}

@font-face {
    font-family: "Titillium Web";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-700.eot");
    src: local(""),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-700.eot?#iefix") format("embedded-opentype"),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-700.woff2") format("woff2"),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-700.woff") format("woff"),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-700.ttf") format("truetype"),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-700.svg#TitilliumWeb") format("svg");
}

@font-face {
    font-family: "Titillium Web";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-700italic.eot");
    src: local(""),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-700italic.eot?#iefix") format("embedded-opentype"),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-700italic.woff2") format("woff2"),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-700italic.woff") format("woff"),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-700italic.ttf") format("truetype"),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-700italic.svg#TitilliumWeb") format("svg");
}

@font-face {
    font-family: "Titillium Web";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-600.eot");
    src: local(""),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-600.eot?#iefix") format("embedded-opentype"),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-600.woff2") format("woff2"),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-600.woff") format("woff"),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-600.ttf") format("truetype"),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-600.svg#TitilliumWeb") format("svg");
}

@font-face {
    font-family: "Titillium Web";
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-600italic.eot");
    src: local(""),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-600italic.eot?#iefix") format("embedded-opentype"),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-600italic.woff2") format("woff2"),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-600italic.woff") format("woff"),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-600italic.ttf") format("truetype"),
        url("/bootstrap-italia/dist/fonts/Titillium_Web/titillium-web-v10-latin-ext_latin-600italic.svg#TitilliumWeb") format("svg");
}

@font-face {
    font-family: "Lora";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("/bootstrap-italia/dist/fonts/Lora/lora-v20-latin-ext_latin-regular.eot");
    src: local(""),
        url("/bootstrap-italia/dist/fonts/Lora/lora-v20-latin-ext_latin-regular.eot?#iefix") format("embedded-opentype"),
        url("/bootstrap-italia/dist/fonts/Lora/lora-v20-latin-ext_latin-regular.woff2") format("woff2"),
        url("/bootstrap-italia/dist/fonts/Lora/lora-v20-latin-ext_latin-regular.woff") format("woff"),
        url("/bootstrap-italia/dist/fonts/Lora/lora-v20-latin-ext_latin-regular.ttf") format("truetype"),
        url("/bootstrap-italia/dist/fonts/Lora/lora-v20-latin-ext_latin-regular.svg#Lora") format("svg");
}

@font-face {
    font-family: "Lora";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("/bootstrap-italia/dist/fonts/Lora/lora-v20-latin-ext_latin-700.eot");
    src: local(""),
        url("/bootstrap-italia/dist/fonts/Lora/lora-v20-latin-ext_latin-700.eot?#iefix") format("embedded-opentype"),
        url("/bootstrap-italia/dist/fonts/Lora/lora-v20-latin-ext_latin-700.woff2") format("woff2"),
        url("/bootstrap-italia/dist/fonts/Lora/lora-v20-latin-ext_latin-700.woff") format("woff"),
        url("/bootstrap-italia/dist/fonts/Lora/lora-v20-latin-ext_latin-700.ttf") format("truetype"),
        url("/bootstrap-italia/dist/fonts/Lora/lora-v20-latin-ext_latin-700.svg#Lora") format("svg");
}

@font-face {
    font-family: "Lora";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("/bootstrap-italia/dist/fonts/Lora/lora-v20-latin-ext_latin-italic.eot");
    src: local(""),
        url("/bootstrap-italia/dist/fonts/Lora/lora-v20-latin-ext_latin-italic.eot?#iefix") format("embedded-opentype"),
        url("/bootstrap-italia/dist/fonts/Lora/lora-v20-latin-ext_latin-italic.woff2") format("woff2"),
        url("/bootstrap-italia/dist/fonts/Lora/lora-v20-latin-ext_latin-italic.woff") format("woff"),
        url("/bootstrap-italia/dist/fonts/Lora/lora-v20-latin-ext_latin-italic.ttf") format("truetype"),
        url("/bootstrap-italia/dist/fonts/Lora/lora-v20-latin-ext_latin-italic.svg#Lora") format("svg");
}

@font-face {
    font-family: "Lora";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url("/bootstrap-italia/dist/fonts/Lora/lora-v20-latin-ext_latin-700italic.eot");
    src: local(""),
        url("/bootstrap-italia/dist/fonts/Lora/lora-v20-latin-ext_latin-700italic.eot?#iefix") format("embedded-opentype"),
        url("/bootstrap-italia/dist/fonts/Lora/lora-v20-latin-ext_latin-700italic.woff2") format("woff2"),
        url("/bootstrap-italia/dist/fonts/Lora/lora-v20-latin-ext_latin-700italic.woff") format("woff"),
        url("/bootstrap-italia/dist/fonts/Lora/lora-v20-latin-ext_latin-700italic.ttf") format("truetype"),
        url("/bootstrap-italia/dist/fonts/Lora/lora-v20-latin-ext_latin-700italic.svg#Lora") format("svg");
}

@font-face {
    font-family: "Roboto Mono";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("/bootstrap-italia/dist/fonts/Roboto_Mono/roboto-mono-v13-latin-ext_latin-regular.eot");
    src: local(""),
        url("/bootstrap-italia/dist/fonts/Roboto_Mono/roboto-mono-v13-latin-ext_latin-regular.eot?#iefix") format("embedded-opentype"),
        url("/bootstrap-italia/dist/fonts/Roboto_Mono/roboto-mono-v13-latin-ext_latin-regular.woff2") format("woff2"),
        url("/bootstrap-italia/dist/fonts/Roboto_Mono/roboto-mono-v13-latin-ext_latin-regular.woff") format("woff"),
        url("/bootstrap-italia/dist/fonts/Roboto_Mono/roboto-mono-v13-latin-ext_latin-regular.ttf") format("truetype"),
        url("/bootstrap-italia/dist/fonts/Roboto_Mono/roboto-mono-v13-latin-ext_latin-regular.svg#RobotoMono") format("svg");
}

@font-face {
    font-family: "Roboto Mono";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("/bootstrap-italia/dist/fonts/Roboto_Mono/roboto-mono-v13-latin-ext_latin-700.eot");
    src: local(""),
        url("/bootstrap-italia/dist/fonts/Roboto_Mono/roboto-mono-v13-latin-ext_latin-700.eot?#iefix") format("embedded-opentype"),
        url("/bootstrap-italia/dist/fonts/Roboto_Mono/roboto-mono-v13-latin-ext_latin-700.woff2") format("woff2"),
        url("/bootstrap-italia/dist/fonts/Roboto_Mono/roboto-mono-v13-latin-ext_latin-700.woff") format("woff"),
        url("/bootstrap-italia/dist/fonts/Roboto_Mono/roboto-mono-v13-latin-ext_latin-700.ttf") format("truetype"),
        url("/bootstrap-italia/dist/fonts/Roboto_Mono/roboto-mono-v13-latin-ext_latin-700.svg#RobotoMono") format("svg");
}

@font-face {
    font-family: "Roboto Mono";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("/bootstrap-italia/dist/fonts/Roboto_Mono/roboto-mono-v13-latin-ext_latin-italic.eot");
    src: local(""),
        url("/bootstrap-italia/dist/fonts/Roboto_Mono/roboto-mono-v13-latin-ext_latin-italic.eot?#iefix") format("embedded-opentype"),
        url("/bootstrap-italia/dist/fonts/Roboto_Mono/roboto-mono-v13-latin-ext_latin-italic.woff2") format("woff2"),
        url("/bootstrap-italia/dist/fonts/Roboto_Mono/roboto-mono-v13-latin-ext_latin-italic.woff") format("woff"),
        url("/bootstrap-italia/dist/fonts/Roboto_Mono/roboto-mono-v13-latin-ext_latin-italic.ttf") format("truetype"),
        url("/bootstrap-italia/dist/fonts/Roboto_Mono/roboto-mono-v13-latin-ext_latin-italic.svg#RobotoMono") format("svg");
}

@font-face {
    font-family: "Roboto Mono";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url("/bootstrap-italia/dist/fonts/Roboto_Mono/roboto-mono-v13-latin-ext_latin-700italic.eot");
    src: local(""),
        url("/bootstrap-italia/dist/fonts/Roboto_Mono/roboto-mono-v13-latin-ext_latin-700italic.eot?#iefix") format("embedded-opentype"),
        url("/bootstrap-italia/dist/fonts/Roboto_Mono/roboto-mono-v13-latin-ext_latin-700italic.woff2") format("woff2"),
        url("/bootstrap-italia/dist/fonts/Roboto_Mono/roboto-mono-v13-latin-ext_latin-700italic.woff") format("woff"),
        url("/bootstrap-italia/dist/fonts/Roboto_Mono/roboto-mono-v13-latin-ext_latin-700italic.ttf") format("truetype"),
        url("/bootstrap-italia/dist/fonts/Roboto_Mono/roboto-mono-v13-latin-ext_latin-700italic.svg#RobotoMono") format("svg");
}

.it-page-sections-container ul li a {
    display: inline;
}

/* Default desktop img-full style inside contents */
.figure.img-full img {
    min-height: 300px;
    height: auto;
    width: 100%;
    max-height: 600px;
    object-fit: cover;
}

/* Overwrite img-full style for mobile */
@media (max-width: 800px) {
    .figure.img-full img {
        min-height: auto;
        height: auto;
        max-height: none;
        object-fit: initial;
    }
}